
body{    
    background: linear-gradient(#f4f7fb, #DDEDFD);
    font-family: 'Inter', sans-serif;
    height: 100vh;
} 
.headerDivBox .companyLogo{
    height: 50px;
}
.cursor-pointer{
    cursor : pointer;
  }
  ::-webkit-scrollbar-track{ 
      background-color: #cacaca;    
    border-radius: 10px;
    cursor: pointer;
  }
  ::-webkit-scrollbar {
      width: 8px;
      background-color: #cacaca;  
    border-radius: 10px;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb {
      background-color: #5b769c; 
    border-radius: 10px;
    cursor: pointer;
  } 
  ::-webkit-scrollbar{
      width: 8px;
    height: 10px;
      background-color: #cacaca;  
    border-radius: 10px;
  }

.header .containerDiv{
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
}
.header .sidenavList{
    list-style: none;
    padding: 0;
    margin: 0;
}
.footerDiv{
    display: flex;
    justify-content: end;
    align-items: center;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
}
.footerDiv button{ 
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
    color: #8E8E9F;
    padding: 8px 16px;
    border: 0;
    border-radius: 50px;
    background: #fff;
}
.footerDiv button img{
    margin-left: 10px;
}
.leftDiv{
    float: left;
}
.rightDiv{
    float: right;
}
.contentBody{
    width: 100%;
    height:calc(100vh - 200px);  
}
.bgInnerDiv{ 
    width: 100%;
    height:calc(100vh - 0px);  
    background: url('../img/bgInner.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}
.bgImageUrl{
    width: 100%;
    height:calc(100vh - 50px); 
    background: url('../img/bgImg.png');
    background-position: bottom;
    background-size: cover;
}
.infoDivBox .logoDiv.leftDiv img{
    height: 80px;
}
.adminDashboardMain .sideBarMenu .logDiv .companyLogo{
    height: 45px;
    margin: 4px 0 0 25px;
}
.urgentTxt{
    font-size: 22px;
    font-weight: 500;
    color: #bf3838;
}
.sideBarOpt{
    width: 100%;
    display: inline-block;
    background: #202040;
    text-align: center;
    color: #fff; 
    padding: 24px 16px 24px 16px; 
    border-radius: 8px;
}
.transcriptMainDiv{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.transcriptMainDiv .leftSideDiv{
    width: 22%;
    padding-left: 20px;
}
.transcriptMainDiv .rightSideDiv{
    width: 88%;
    display: inline-block;
    justify-content: space-between;
    padding: 0 20px;
    height: calc(100vh - 170px);
    overflow-y: scroll;
}
.transcriptMainDiv .rightSideDiv .listViewDiv{ 
    padding: 20px; 
    border-radius: 8px ; 
    width: 100%;
    background: #FFFFFF;
    height: fit-content;
    box-shadow: 0px 4px 8px 0px #00000008;

}
.transcriptMainDiv .rightSideDiv .summaryDiv{
    box-shadow: 0px 4px 8px 0px #00000008;
    margin-bottom: 20px;
    border-radius: 8px ; 
    width: 100%;
    background: #FFFFFF;
    height: fit-content;
    position: relative;
    display: inline-block;
}
.transcriptMainDiv .rightSideDiv .summaryDiv span{
    padding: 15px 20px 10px; 
    display: inline-block;
}
.transcriptMainDiv .rightSideDiv .summaryDiv span h3{
 
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;   
    margin-bottom: 15px;
}
.actionBtnList{
    list-style: none;
    padding: 0 20px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.actionBtnList li{
    margin-right: 15px;
}
.header .sidenavList .profileImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0px auto;
    background: #202040;
    color: #fff;
    font-size: 20px;
    font-weight: 400; 
    text-align: center;
}
.transcriptMainDiv .sideBarOpt span.imgBox{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0px auto 20px;
    background: #fff;
}
.transcriptMainDiv .sideBarOpt span.imgBox b{

    color: #202040;
    font-size: 36px;
    font-weight:500; 
    text-align: center;
}
.header .sidenavList .profileImg img,
.transcriptMainDiv .sideBarOpt span.imgBox img{
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: center;
    display: none;
} 
.transcriptMainDiv .leftSideDiv h2{ 
    font-size: 18px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
}
.transcriptMainDiv .leftSideDiv label{ 
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;

}
.transcriptMainDiv .leftSideDiv b{ 
    font-size: 15px;
    font-weight: 400;
    line-height: 13.31px;
    text-align: center;
    margin-right: 8px;
}
.transcriptMainDiv .leftSideDiv span{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.transcriptMainDiv .leftSideDiv button.playBtn    {
    border: 1px solid #F6FBFF;
    background: transparent;
    padding: 10px 20px; 
    font-size: 14px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: center;
    border-radius: 50px;
    color: #FFFFFF;
    margin-top: 20px;
}
.transcriptMainDiv .rightSideDiv button.playBtn img{
    margin-right: 6px;
}
.RecorderAudio div{
    background: transparent !important;
    text-align: center;
}

._1ceqH ._f2DT8 {
    text-align: center;
    color: #000;
}
._1ceqH ._1YOWG ._eV_dK,
._1ceqH ._f2DT8 * {
    color: #000; 
}
._1ceqH ._qxztz,
._1ceqH ._3bC73 ._1dpop { 
    bottom: -30px;
}
._1ceqH ._3bC73 ._1YOWG {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    bottom: 0;
}

.transcriptMainDiv .rightSideDiv .listViewDiv ul{
    list-style: none;
    padding: 0;
}

.transcriptMainDiv .rightSideDiv .listViewDiv ul li b{
      font-weight: 600; 
      display: flex;
}

.transcriptMainDiv .rightSideDiv .listViewDiv ul li{
 
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: -0.019em;
    text-align: left;
    margin-bottom: 15px;
}
.afterRecordDiv button,
.startRecordDiv button{
       padding: 16px 24px;
       border-radius: 50px;  
        font-size: 16px;
        font-weight: 600;
        line-height: 16.94px;
        text-align: center;
        color: #fff;
        background: #F43D35;
        border: 0;
        width: 150px;
        margin-left: 15px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .startRecordDiv button{
        margin-left: 0;
    }
    .startRecordDiv {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    .startRecordDiv button.getMicOnBtn{
        width: 225px;
    }
    .startRecordDiv button.getMicOnBtn img{
        width: 24px;
        filter: brightness(10);
    }
.startRecordDiv button.blueBtn{
    background: #202040;
    margin-right: 10px;
}
.startRecordDiv button img,
.startRecordDiv button img{
    margin-left:5px;
}
.recordingMainDiv h1{ 
    font-size: 45px;
    font-weight: 600;
    line-height: 54.46px;
    text-align: center;
    color: #000;
}
.recordingMainDiv p{ 
    color: #F43D35;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
}
.timerWaveBarDiv{
    height: 200px;
    display: block;
}
.allSessionMainDiv.transcriptMainDiv .leftSideDiv {
    width: 400px;
    padding-left: 20px;
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv {
    width: calc(100% - 400px);
    display: inline-block;
} 
.allSessionMainDiv .leftSideDiv .sideBarOpt {
    width: 100%;
    display: flex;
    background: transparent;
    text-align: left;
    color: #000;
    padding: 14px;
    border-radius: 0px;
    align-items: center;
    justify-content: start;
}

.allSessionMainDiv.transcriptMainDiv .leftSideDiv ul{
    padding: 0;
    list-style: none;
    height: 80vh;
    overflow-y: auto;
    display: inline-block;
    width: 100%;
    padding-right: 10px;
}
.allSessionMainDiv.transcriptMainDiv .leftSideDiv ul li{
    margin-bottom: 20px;
    background: #f7f7f7;
    border-radius: 8px;    
    box-shadow: 0px 4px 8px 0px #00000020;
}
.allSessionMainDiv.transcriptMainDiv .leftSideDiv ul li:hover,
.allSessionMainDiv.transcriptMainDiv .leftSideDiv ul li.active{
    background: #202040; 
    box-shadow: 0px 4px 8px 0px #0000003b;
}

.allSessionMainDiv.transcriptMainDiv .leftSideDiv ul li:hover .sideBarOpt,
.allSessionMainDiv.transcriptMainDiv .leftSideDiv ul li.active .sideBarOpt{
    color: #fff;
}
.allSessionMainDiv.transcriptMainDiv .sideBarOpt span.imgBox {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0px; 
    background: #ECF5FE;
    float: left;
}
.allSessionMainDiv.transcriptMainDiv .sideBarOpt span.imgBox b{ 
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    padding: 0;
    margin: 0;
}
.allSessionMainDiv.transcriptMainDiv .leftSideDiv span.innerTextDescp {
    display: inline-block;
    justify-content: center;
    align-items: center; 
    width: 75%;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0;
}
.allSessionMainDiv.transcriptMainDiv .leftSideDiv h2{
    text-align: left;
    margin-bottom: 5px;

}
.allSessionMainDiv.transcriptMainDiv .leftSideDiv h2.sessionTxt{
    font-size: 20px;
    margin-bottom: 20px;
}
.allSessionMainDiv.transcriptMainDiv .leftSideDiv span {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 5px;
    text-align: left;
}
.allSessionMainDiv.transcriptMainDiv .leftSideDiv b img{
    filter: invert(1);
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv .listViewDiv,
.allSessionMainDiv.transcriptMainDiv .rightSideDiv .summaryDiv{
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv .listViewDiv{
   
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv {
    margin-top: 40px;
    height: calc(100vh - 220px);
    overflow-y: scroll;
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv .sideBarOpt {
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    float: left;
    display: inline-block;
    border-radius: 8px 8px 0 0;
    padding: 16px;
    margin-bottom: 0;
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv .sideBarOpt span.imgBox {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv .sideBarOpt span{
  
    display: flex;
    /* width: 90%; */
    justify-content: space-between;
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv .sideBarOpt h2{
    margin: 0;
    display: flex;
    align-items: center;
}
.transcriptMainDiv .sideBarOpt span.imgBox b {
    color: #202040;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
}
.transcriptMainDiv .rightSideDiv button.playBtn,
.allSessionMainDiv.transcriptMainDiv .rightSideDiv button.playBtn {
    border: 2px solid #202040;
    background: transparent;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: center;
    border-radius: 50px;
    color: #202040; 
    float: right;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv button.playBtn  img{
    margin-right: 8px;
}

.infoTxtDescp{
    float: right;   
    display: inline-block;
    width: 180px;
    text-align: left;
}
.allSessionMainDiv.transcriptMainDiv .rightSideDiv .sideBarOpt .infoTxtDescp span { 
    justify-content: start;
}
.RecorderAudio div.successMsgBox{
    background: #51516C !important; 
    padding: 16px 32px;
    gap: 10px;
    border-radius: 10px;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: center; 
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px; 
    text-align: left;
    position: absolute;
    top: 100px;
}
.recordingMainDiv {
    margin-top: 100px;
}
.spinner-border{
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.centerLoader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  .centerLoader .wave {
    width: 8px;
    height: 100px;
    background: linear-gradient(45deg, red, #000);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  .loginScreen{
    display: flex;
    justify-content: space-between;
    padding-top: 50px !important;
  }
  .loginScreen .logoDiv{
    width: 100%;
    margin-bottom: 50px;
  }
  .loginScreen .infoDivBox h1{ 
    font-size: 60px;
    font-weight: 400;
    line-height: 67px;
    text-align: left;
    color: #000;
    margin-bottom: 30px;

  }
  .loginScreen .infoDivBox p{ 
    font-size: 19px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #3C3C3C;
  }
  .loginScreen .infoDivBox ul{
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    width: 700px;
  }
  .loginScreen .infoDivBox ul li{
    width: 215px;
    display: flex;
    align-items: center;
  }
  .loginScreen .infoDivBox ul li img{
    margin-right: 5px;
  }
  .loginScreen .infoDivBox span h3{ 
    font-size: 32px;
    font-weight: 400;
    line-height: 67px;
    text-align: left;
    color: #000;
}
.loginScreen .infoDivBox span p{
    color: #000;
    font-size: 19px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    
}
.loginScreen .infoDivBox {

}
.loginScreen .infoDivBox {

}
.loginScreen .infoDivBox {

}
.whiteBgColor{
    background: rgb(255 255 255 / 60%);
}
.loginBoxDiv {
    box-shadow: 0px 0px 15px 0px #00000021;
    padding: 30px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.loginBoxDiv .switchToogle input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.loginBoxDiv .switchToogle label {
	cursor: pointer;
	text-indent: -9999px;
    width: 50px;
    height: 24px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.loginBoxDiv .switchToogle label:after {
	content: '';
	position: absolute;
	top: 4px;
    left: 5px;
    width: 16px;
    height: 16px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.loginBoxDiv .switchToogle input:checked + label {
	background: #bada55;
}

.loginBoxDiv .switchToogle input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.loginBoxDiv .switchToogle label:active:after {
	width: 60px;
}

.loginBoxDiv .inputTxtBox{
    box-shadow: 0px 1px 4px 0px #00000024;
    background: #FFFFFF;
    border-radius: 50px;
    padding: 10px;
    position: relative;
    margin-bottom: 30px;
}
.loginBoxDiv .inputTxtBox input{
    font-size: 16px;
    background: transparent;
    border: 0;
    width: calc(100% - 60px);
    position: absolute; 
    float: right;
    right: 10px; 
}
.loginBoxDiv .inputTxtBox .gender-selector{
    width: 200px;
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.loginBoxDiv .inputTxtBox .gender-selector input{
    height: 16px;
    background: transparent;
    border: 0;
    width: 16px;
    position: relative; 
    float: none;
    right:unset; 
    margin-right: 4px;
}
.loginBoxDiv .inputTxtBox.disabled,
.registerInputDiv.disabled  .react-tel-input input {
    background: #E9E9E9;
}
.registerInputDiv .react-tel-input  input {
    box-shadow: 0px 1px 4px 0px #00000024;
}
select:focus, 
input:focus{
    outline: none;
}
.loginBoxDiv .inputTxtBox img{
    padding-left: 5px;
}
.loginBoxDiv .inputTxtBox img.lockedPwd{
    position: absolute;
    top: 14px;
    right: 20px; 
}
.optDivBox .switchToogle label{
    float: left; 
}
.optDivBox .forgotPwd{
    float: right;
}
.signInBtn{
    background: #024C9C;
    color: #fff; 
    font-size: 18px;
    font-weight: 400;
    line-height: 27.92px;
    text-align: center;
    width: 100%;
    height: 64px;
    border-radius: 50px;
    border: 0;
    margin: 20px auto;
}
.otherSignInOpt{
    text-align: center; 
    font-size: 16px;
    font-weight: 400;
    line-height: 21.84px;
    text-align: center;

}
.otherSignInOpt ul{
    list-style: none;
    padding: 0;
    display: flex;
    /* display: grid;
    grid-template-columns:50% 50%; */
}
.otherSignInOpt ul li{
    box-shadow: 0px 1px 4px 0px #00000024;
    background: #FFFFFF;
    height: 52px; 
    font-size: 16px;
    font-weight: 500;
    line-height: 19.11px;
    text-align: left;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin: 8px 5px;
    padding: 0 10px;
    cursor: pointer;
}
.otherSignInOpt ul li img{
    width: 20px;
    height: 20px; 
    margin: 0 10px 0 5px;
}
.signupDiv{
    text-align: center; 
    font-size: 14px;
    font-weight: 400;
    line-height: 21.84px;
    text-align: center;
    margin-top: 0px;
}
.react-tel-input .form-control {
    position: relative;
    font-size: 16px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 0px solid #CACACA;
    border-radius: 50px;
    line-height: 25px;
    height: 45px;
    width: 100%;
    outline: none;
}
.react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 48px;
    height: 100%;
    padding: 0 0 0 12px;
    border-radius: 3px 0 0 3px;
}
.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: 0px solid #cacaca; 
    border-radius: 50px 0px 0 50px; 
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: transparent;
}
.emailVerificationDiv{
    text-align: center;
    display: inline-block;
}
.emailVerificationDiv img{
    margin-bottom: 40px;
}
.emailVerificationDiv .signInBtn{
    width: 150px;
    height: 52px;
}
.titleDespTxt{
    margin-bottom: 20px;
}
.whiteBg{
    background: #fff;
}
.headerDivBox {
    display: flex;
    width: 100%;
    padding: 5px;
    justify-content: space-between;
}
.headerDivBox .sidenavList {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 130px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
}
.headerDivBox .sidenavList li.homeIconDiv img{
    width: 30px;
    margin: 5px 10px;
    cursor: pointer;
}
.headerDivBox .sidenavList li.listOpt.accordion {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.headerDivBox .sidenavList li.listOpt .accordion-collapse.collapse.show {
    animation-iteration-count: 0 !important;
    background: #fff;
    bottom: auto !important;
    box-shadow: 0 10px 27px #00000026;
    display: inline-block;
    left: auto !important;
    position: absolute;
    right: 10px !important;
    top: 64px !important;
    transform: none !important;
    transition: none !important;
    width: 260px;
    z-index: 9;
    padding: 20px;
}
.headerDivBox .sidenavList li.listOpt .accordion-collapse.collapse.show ul {
    list-style: none;
    padding: 0;
}
.headerDivBox .sidenavList li.listOpt .accordion-collapse.collapse.show ul li{
    font-size: 15px;
    font-weight: 400;
    line-height: 20.48px;
    text-align: left;
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: start;
    cursor: pointer;
}
.headerDivBox .sidenavList li.listOpt .accordion-collapse.collapse.show ul li b{
    background: #F3F7F8;
    width: 40px;
    height: 40px; 
    border-radius: 40px; 
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 15px;
}

.headerDivBox .sidenavList li.listOpt .accordion-collapse.collapse.show ul li b img{
    object-fit: contain;  
}
.headerDivBox .sidenavList .profileImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0px auto;
    background: #202040;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}
.BMI_Dashboard .progressbar {
    counter-reset: step;
  }
  .BMI_Dashboard .progressbar li {
    list-style: none;
    display: inline-block;
    width: 30.33%;
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  .BMI_Dashboard  .progressbar li:before {
    /* content: counter(step);
    counter-increment: step; */
    content: "";
    width: 30px;
    height: 30px;
    line-height : 30px;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #fff; 
  }
  .BMI_Dashboard .progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index : -1;
  }
  .BMI_Dashboard .progressbar li:first-child:after {
    content: none;
  }
  .BMI_Dashboard .progressbar li.active {
    color: green;
  }
  .BMI_Dashboard .progressbar li.active:before {
    border-color: green;
  } 
  .BMI_Dashboard .progressbar li.active + li:after {
    background-color: green;
  }
  .stepFirstForm{
    background: rgb(255 255 255 / 60%);
    box-shadow: 0px 3px 21px 1px #0000001A;
    border-radius: 14px;
    padding: 30px 25px;
    display: flex;
    margin-top: 50px !important;
  }
.stepFirstForm .inputDiv{
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    margin: 12px auto 0;
}
.stepFirstForm .inputDiv label{ 
    font-size: 15px;
    font-weight: 500;
    line-height: 18.9px;
    text-align: left;
    color: #8F8F8F;

}
.stepFirstForm .inputDiv input{
    width: 87%;
    border: 0;
}

.stepFirstForm .inputDiv:last-child input{
    width: 86%;
    border: 0;
}
.stepFirstForm  .nextBtn{
    background: #024C9C;
    color: #fff; 
    font-size: 20px;
    font-weight: 400;
    line-height: 27.92px;
    text-align: center;
    border-radius: 8px;
    padding: 20px;
}
.attachmentSpan{
    display: flex;
    align-items: center;
    justify-content: space-between; 
    position: absolute;
    right: 10px;
}
.attachmentSpan a{
    border: 0;
    border-radius: 10px;
    padding: 11px 12px;
    margin-left: 10px;
    background: #ececec;
    cursor: pointer;
}
.attachmentSpan a img{
    height: 15px;
    width: 14px;
}
.fillDetailsForm label{
    width: auto;
}
.stepFirstForm .inputDiv.fillDetailsForm {
    justify-content: left;
    position: relative;
    padding: 16px 8px;
}
.stepFirstForm .inputDiv.fillDetailsForm input {
    width: 55%;
    padding-left:15px;
}

.chatbotConversation{
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 3px 21px 1px #0000001A;
    padding: 40px;
    margin: 50px auto;
    width: 60%;
    position: relative;
}
.chatbotConversation .messageListBox{ 
    height: calc(100vh - 400px);
    overflow-y: auto;
    margin-bottom: 50px;
}
.chatbotConversation h2{ 
    font-size: 24px;
    font-weight: 600;
    line-height: 33.5px;
    text-align: left;
    color: #000;
    margin-bottom: 30px;

}
.chatbotConversation .chatbot{
    display: grid;
    grid-template-columns: 50px 90%;
    gap: 10px;
    margin-bottom: 20px;
}
.chatbotConversation .chatbot .imgBox{
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0px 1px 4px 0px #00000021;
    float: left; 

}
.chatbotConversation .chatbot .AI_dateTime{
    display: flex;
    justify-content: left;
    align-items: baseline;
    height: 20px; 
    font-size: 13px;
    font-weight: 800;
    line-height: 22.01px;
    text-align: left;
    color: #4A5E88;

}
.chatbotConversation .chatbot .AI_dateTime ul{ 
    padding: 0;
    margin: 0 0px 0 30px; 
    font-size: 12px;
    font-weight: 400;
    line-height: 16.75px;
    text-align: left;
    color: #929BAC;

}
.chatbotConversation .chatbot .quesTxt{
    color: #515151;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    }
.chatbotConversation .chatbot .questOpt{ 
font-size: 13px;
font-weight: 500;
line-height: 22px;
text-align: left;
color: #515151;
margin-top: 20px;
}
.chatbotConversation .chatbot .questOpt b{ 
    font-weight: 500;
}
.chatbotConversation .chatbot .questOpt ul {
    padding: 0;
    list-style: none;
    margin-top: 14px;
    display: inline-block;
}
.chatbotConversation .chatbot .questOpt ul li{ 
    border-radius: 50px;
    padding: 6px 12px;
    margin-right: 10px;
    float: left;
    margin-bottom: 10px;
    border: 1px solid #E2E2E2;
}
.chatbotConversation .chatbot .questOpt ul.illnessesListOpt li.active, 
.chatbotConversation .chatbot .questOpt ul.illnessesListOpt li:hover{
    background: #024C9C;
    color: #fff;
    cursor: pointer;
 }
.chatbotConversation .chatbot .questOpt ul.illnessesListOpt.selcted li.disabled{
    background: #dbdbdb;
    cursor: default;
    opacity: 0.5;
}

.QueryInputDivBox{
    display: flex;
    justify-content: space-between; 
    position: relative; 
    width: 100%;
}
.QueryInputDivBox .inputField{
    display: flex;
    justify-content:space-between;
    align-items: center;
    border: 1px solid #F3F3F3;
    padding: 10px 14px;
    border-radius: 10px;
    position: relative;
    width: calc(100% - 110px);
}
.QueryInputDivBox .inputField input{
    width: 100%;
    border: 0;
    padding-right: 40px;
}
.QueryInputDivBox .inputField a{
    position: absolute;
    right: 10px; 
    border: 0;
    border-radius: 10px;
    padding: 5px 10px;
    margin-left: 10px;
    background: #ececec;
    cursor: pointer;
}
.QueryInputDivBox .inputField a img{
    height: 13px;
    width: 12px;
    opacity: 0.7;
}
.QueryInputDivBox .btnSendQuery{
    width: 95px;
    background: #024C9C;
    box-shadow: 0px -3px 5px 0px #00000012 inset;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.94px;
    text-align: center;
    border-radius: 10px;
    border: 0;
}
.QueryInputDivBox .btnSendQuery img{
    width: 12px;
    margin-left: 12px;
}
 .chatbotConversation .chatbot.userReply .imgBox{
    border-radius: 50px;
 }
 .twoDivRow{
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;
 }
 .twoDivRow .inputTxtBox{
    width: 48%;
    margin: 0;
 }
 .inputTxtBox.registerInputDiv, .registerInputDiv{
    margin: 20px 0 0;
 }
 .signInBtn.registerBtn{
    margin-top: 20px;
 }
 .stepFirstForm .inputDiv.radioBtnOptDiv{
    display: inline-block;
    width: 100%;
 }
 .stepFirstForm .inputDiv.radioBtnOptDiv h5{
    font-size: 18px; 
    font-weight: 500;
    line-height: 18.9px;
    text-align: left;
    color: #8F8F8F;
 }
 .stepFirstForm .inputDiv.radioBtnOptDiv span{
    display: block;
    color: #8F8F8F;
 }
 .stepFirstForm .inputDiv.radioBtnOptDiv span h3{
    font-size: 16px;
    margin-right: 5px;
    display: inline-block; 
    font-weight: 500;
    line-height: 18.9px;
    text-align: left;
    color: #8F8F8F;
 }
 .stepFirstForm .inputDiv.radioBtnOptDiv span input{
    width: 60px;
    border-bottom: 2px solid #8F8F8F;
 }
 .stepFirstForm .inputDiv.radioBtnOptDiv span ul{ 
    list-style: none;
    padding: 0;
 } 
 .stepFirstForm .inputDiv.radioBtnOptDiv span ul.twoDivClass li {
    width: 45%; 
    display: inline-block;
}
.stepFirstForm .inputDiv.radioBtnOptDiv span ul.threeDivClass li {
   width: 25%; 
   display: inline-block;
}
 .stepFirstForm .inputDiv.radioBtnOptDiv span{}
 .stepFirstForm .inputDiv.radioBtnOptDiv span{}
.signInBtn:disabled{
    background: #999;
    opacity: 0.5;
 }
 .clearDate{
    border: 0;
    background: #aeaeae;
    float: right;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-top: 2px;
    position: relative;
    z-index: 1;
 }
 input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent !important;
    color: fieldtext !important;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.fillDetailsMainForm{
    height: 50vh;
    overflow-y: auto;
    margin-bottom: 0px;
    padding-right: 10px;
}
.stepFirstForm h2{
    font-size: 24px;
}
.ForgotPassswordMain{
    width: 650px;
    left: 50%;
}
.eyePwdIcon{
    float: right;
    position: relative;
    z-index: 2;
    cursor: pointer;
}
.eyePwdIcon img{
    width: 32px;
    margin-right: 10px;
}
.inputGroupDiv{
    margin-bottom: 20px;
}
.inputGroupDiv .inputTxtBox.registerInputDiv, 
.inputGroupDiv .registerInputDiv{
    margin-top: 0;
}
.labelTxt{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}
.ForgotPassswordMain h2{
    margin-bottom: 20px;
}
.ForgotPassswordMain .signInBtn{
    margin: 20px auto;
}

.spinnerTxt { 
    width: 70px;    
    text-align: left;
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
  .spinnerTxt > div {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background-color: #666565;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .spinnerTxt .bounce1 {
    animation-delay: -0.32s;
  }
  .spinnerTxt .bounce2 {
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  .toggleCalendarBtn{
    float: right;
    position: relative;
    z-index: 1;
    border: 0;
    background: transparent;
  }
.toggleCalendarBtn img{
    width: 28px;
  }
  .react-calendar.inputCaledar{
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 1;
  } 
  .attachmentListData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 5px 8px 2px;
}
    .attachmentListData p{
        margin-bottom: 0;
     }
    .attachmentListData table {
        padding: 0;
        width: 100%;
    }
    .attachmentListData table td{
        padding: 5px;
    }
    .attachmentListData table td:last-child{
        display: flex;
        justify-content: end;
    }
    .attachmentListData table tr.uploadFilelistOpt{
        display: grid;
        grid-template-columns: 90% 10%;
        border-bottom:1px solid #dfdfdf;
        padding: 8px 0;
        margin-bottom: 0px;
    }
  .attachmentDeleteBtn{
    border: 0;
    padding: 2px 5px;
    border-radius: 5px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    box-shadow: 0px 2px 2px 0px #00000057;
  }
  .attachmentDeleteBtn img{
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
    }
.stepFirstForm .inputDiv.newFillDetailForm{
    display: inline-block;
    width: 100%; 
}
.stepFirstForm .inputDiv.newFillDetailForm label{    
    display: block;
    width: 100%;
}
.stepFirstForm .inputDiv.newFillDetailForm textarea{
    width: calc(100% - 100px);
    height: 100px;
    margin: 5px 0;
    float: left; 
    border: 1px solid #E5E7EB;
} 
.stepFirstForm .inputDiv.newFillDetailForm .attachmentSpan{
    padding: 10px 0 0;
}
.uploadFilelistMain .attachmentSpan{
    position: absolute;
    top: 0;
} 
.uploadFilelistMain .attachmentListData{
    width: 100%;
    display: inline-block;
    margin-top: 20px;
}
.uploadFilelistOpt {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.uploadFilelistOpt p{
    width:auto;
    font-size: 0.85em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
}
.UserProfileMain{
    width: 1000px !important;
}
.UserProfileMain h2{
    font-size: 24px;
    padding-left: 16px;
}
.UserProfileMain .editUserProfile,
.UserProfileMain .showUserProfile{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    width: 99%;
    padding: 0 30px 0 20px;
}
.UserProfileMain .editUserProfile label,
.UserProfileMain .showUserProfile p label{
    display: block;
    font-weight: 700;
}
.myinsuranceinfoMain  .editIcon,
.UserProfileMain .editIcon{
    border: 0;
    background: #013c7d;
    color: #fff;
    height: 24px;
    float: right;
    padding: 15px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.myinsuranceinfoMain  .editIcon.btnactive,
.UserProfileMain .editIcon.btnactive{
    background: #fff;
    color: #000;
}
.myinsuranceinfoMain  .editIcon img,
.UserProfileMain .editIcon img{
    margin-left: 5px;
}
.UserProfileMain .saveOptDivBox { 
    display: flex;
    justify-content: center;
    padding: 0 0 0 20px;
    margin-top: 50px;
}
.myinsuranceinfoMain  .saveOptDivBox button,
.UserProfileMain .saveOptDivBox button{
    border-radius: 50px;
    padding: 6px 12px;
    margin-right: 20px;
    float: left;
    margin-bottom: 10px;
    border: 1px solid #E2E2E2;
    background: #024C9C;
    color: #fff;
    cursor: pointer;
    width: 150px;
}
.loginBoxDiv .inputTxtNewBox{
    box-shadow: 0px 1px 4px 0px #00000024;
    background: #FFFFFF;
    border-radius: 50px;
    padding: 10px;
    position: relative;
    margin-bottom:0px;
}
.UserProfileMain  .inputTxtBox {
    box-shadow: 0px 1px 4px 0px #00000024;
    background: #FFFFFF;
    border-radius: 50px;
    padding: 10px;
    position: relative;
    margin-bottom: 30px;
}
.UserProfileMain .inputTxtBox img {
    padding-left: 5px;
}
.UserProfileMain .inputTxtBox input {
    font-size: 16px;
    background: transparent;
    border: 0;
    width: calc(100% - 60px);
    position: absolute;
    float: right;
    right: 10px;
}
.UserProfileMain  .newInputTxtBox input { 
    position: relative;
    float: left;
    right: 0px;
    padding-left: 10px;
    width: calc(100% - 70px);
}
.UserProfileMain  .newInputTxtBox label { 
    text-align: right;
}
.UserProfileMain .inputTxtBox.registerInputDiv, 
.UserProfileMain .registerInputDiv {
    margin: 10px 0 0;
    height: 50px;
}
.UserProfileMain .inputDivBox{
    width: 100%;
}
.iconInput{
    width: 32px;
    float: left;
}
.disabledTxtField, .disabledTxtField input{
    background: #f3f3f3 !important;
}
.react-tel-input .form-control {  
    box-shadow: 0px 1px 4px 0px #00000024;
}
.errorTxt{
    color: red;
    font-size: 14px;
}
.centerDivAlign{
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

 
  div.showLoaderDot span {
    width: 10px;
    height: 10px;
    background-color: #999;
    display: inline-block;
    margin: 1px;
    border-radius: 50%;
  }
  div.showLoaderDot span:nth-child(1) {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite;
  }
  div.showLoaderDot span:nth-child(2) {
    -webkit-animation: bounce 1s infinite 0.2s;
            animation: bounce 1s infinite 0.2s;
  }
  div.showLoaderDot span:nth-child(3) {
    -webkit-animation: bounce 1s infinite 0.4s;
            animation: bounce 1s infinite 0.4s;
  }
  
  @-webkit-keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(8px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(8px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .modelBoxDiv{
    background: rgb(0 0 0 / 20%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modelBoxDiv .innerDiv .modal-content{
    height: 320px;
    width: 565px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  .modelBoxDiv .innerDiv .modal-content .imgDiv{
    width: 50px;
    height: 50px;
  }
  .modelBoxDiv .innerDiv .modal-content ul.successListPoint {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin: 10px 0 0;
  }
  .modelBoxDiv .innerDiv .modal-content h2{
    background: transparent;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
  }
  .modelBoxDiv .innerDiv .modal-content button{
    background: #024c9c;
    border: 0;
    border-radius: 50px;
    color: #fff;
    font-size: 18px;
    height: 54px;
    line-height: 27.92px;
    margin: 20px auto 0;
    width: 250px;
  }
  .submitQueryBoxDiv{
    width: 100%;
    display: inline-block;
  }
.sideBarMenu {
    width: 250px;
    display: inline-block;
    height: 100vh;
    float: left; 
    box-shadow: 0px 0px 3px 0px #00000021;
    position: relative;
}
.headerDivBox.adminHeader{
    width: 100%;
    display: inline-block; 
    float: right;
    box-shadow: 0px 0px 3px 0px #00000021;

}
.adminDashboardMain .contentBody{
    width: calc(100% - 250px);
    display: inline-block; 
    float: right; 
    height: 100vh; 
}
.adminDashboardMain .headerDivBox .sidenavList { 
    width: auto;
}
.sideBarMenu .logDiv{
    height: 60px;
    width: 100%;
}
.sideBarMenu ul {
    list-style: none;
    padding: 0;
}

.adminDashboardMain .sideBarMenu ul.sidenavList li{ 
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #1C1C1C; 
    margin: 8px auto;
    padding: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 90%;
    border-radius: 6px;
    cursor: pointer;
} 
.adminDashboardMain .sideBarMenu ul.sidenavList li.active{
    background: #024C9C;
    color: #fff; 
}

.adminDashboardMain .sideBarMenu ul.sidenavList li img{
    margin-right: 14px;
    width: 24px;
}
.adminDashboardMain .sideBarMenu ul.sidenavList.bottomPosition {
    position: absolute;
    left: 10px;
    bottom: 0;
    border-top: 1px solid #dcdcdc;
    width: 90% !important;
    padding: 30px 0;
    margin: 0;
}
.adminDashboardMain .innerBodyBox{
    width: 100%;
    display: inline-block; 
    float: right; 
    height: calc(100vh - 60px); 
    margin-top: 0px; 
}
.adminDashboardMain .innerBodyBox.bgInnerDiv { 
    background: url('../img/admin/bgImg.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

.table-options {
    margin: 0;
  }
  
  .table-container {
    height:  calc(100vh - 210px);
    width: 96%;
    overflow-y: auto;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 0px;
    border: 2px solid #E6E6E6;
    background: #fff;
    margin: auto;
  }
  
  table {
    width: 98%;
    margin: auto;
    border-collapse: collapse;
 
  }
  
  thead {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
    border-radius: 20px;
  }
  .whiteBg{
    background: #fff;
  }
  th, td {
    padding: 20px 30px;
    text-align: left;
    border: 0px solid #ddd; 
    font-size: 15px;
    font-weight: 400;
    line-height: 20.48px;
    text-align: left;
    color: #000000;

  }
 tbody tr td{
    border-bottom: 1px solid #E3E3E3
  }
  tbody tr:last-child td{
    border-bottom: 0px solid #E3E3E3
  }
  thead  th{
    padding: 25px 30px 10px; 
    font-size: 13px;
    font-weight: 400;
    line-height: 17.75px;
    text-align: left;
    color: #8D8D8D;
    border-bottom: 1px solid #E3E3E3
  }
  .totalPageDiv{
    text-align: center; 
    border: 1px solid #E6E6E6;
    background: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.75px;
    letter-spacing: -0.2800000011920929px;
    text-align: right;
    color: #363C4F;
    border-radius: 50px;
    padding: 0px 15px;
    margin: 0px 5px;
    width: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 40px;
  }
 .paginationMain{
    display: flex;
    justify-content:space-between ;
    align-items: center;
    padding: 15px 25px;
 }
  .pagination {
    text-align: center; 
    border: 1px solid #E6E6E6;
    background: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.75px;
    letter-spacing: -0.2800000011920929px;
    text-align: right;
    color: #363C4F;
    border-radius: 50px;
    padding: 0px 15px;
    margin: 0px 5px;
    width: 270px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .pagination button{
    border: 0;
    background: transparent;
    height: 40px;
  }
  .page-button { 
    text-align: center;
    width: 40px;
    margin: 0px;
    border: none;
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  .page-button.active {
    background-color: #024C9C; 
    color: white;
  }
  
  .page-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  .titleRightSideDiv{ 
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: 0;
  }
  .titleRightSideDiv li{
    border: 1px solid #E6E6E6;
    background: #fff; 
    font-size: 13px;
    font-weight: 400;
    line-height: 17.75px;
    letter-spacing: -0.2800000011920929px;
    text-align: right;
    color: #363C4F;
    border-radius: 50px;
    padding: 10px 15px;
    margin: 0 5px;
  }
  .contentInnerBody .titleDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 10px;
  }
  #recordsPerPage{
    border: 0;
  }
  .innerDivRecords{
        margin: 30px 30px 0;
        border-radius: 14px;
        padding: 0 0 30px;
    }
    .viewPatientMain .table-container {
        max-height: calc(100vh - 240px);
        width: 100%;
        overflow-y: auto;
        margin-bottom: 20px;
        border: 0px;
        padding: 0px;
    }
    .viewPatientMain .table-container thead th {
        padding: 15px 30px 10px 20px;
    }
    .viewPatientMain .table-container tbody td {
        padding: 20px 30px 20px 20px;
    }
    .patientDataListMain .table-container tbody tr.active,
    .viewPatientMain .table-container tbody tr.active{
        background: #bedbdf;
    }
    .patientDataListMain .table-container tbody tr.active td,
    .viewPatientMain .table-container tbody tr.active td {
        font-weight: 700;
    } 
   .userImgIconCircleDiv {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background: #7e8ddd;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-right: 10px;
    }
   .innerDivRecords h2 { 
        font-size: 20px;
        font-weight: 600;
        line-height: 27.92px;
        text-align: left;
        margin-bottom: 0;
    }
    .contentInnerBody .viewPatientMain .titleDiv { 
        padding: 20px 30px 0px;
    }
    .viewPatientMain .titleRightSideDiv{
        margin-bottom: 0;
    }
    .viewPatientMain .innerDivRecords {
        margin: 20px 30px 0; 
    }
    .userInfoMainDiv{
        display: flex;
        justify-content: left;
        align-items: start;
        padding: 20px 20px 12px;
    }
    .userInfoMainDiv .imgBox{
        margin-right: 20px;
        width: 110px; 
        height: 110px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e9e9e9;
        overflow: auto;
    }
    .userInfoMainDiv .imgBox b{
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
    }
    .userInfoMainDiv .imgBox img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .userInfoMainDiv .titleDivRight{
        width: calc(100% - 130px);
        position: relative;
    }
    .userInfoMainDiv .titleDivRight .backBtn{
        background-color: #024C9C;
        color: white;
        border-radius: 8px;
        border: 0;
        font-size: 15px;
        padding:  7px 10px 7px 7px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 80px;
        position: absolute;
        right: 0;
        top: 0;
    }
    .userInfoMainDiv .titleDivRight .backBtn img{
        filter: grayscale(20) brightness(5);
        width: 18px;
    }
    .userInfoMainDiv ul.userMedicalInfo,
    .userInfoMainDiv ul.userInfoDetails{
        list-style: none;
        padding: 0;
        display: flex;
        margin-bottom: 8px;
    }
   .capitalizeTxt{
        text-transform: capitalize;
    }
    .userInfoMainDiv ul.userInfoDetails li {
        margin-right: 20px;
        display: flex;
        align-items: center;
    }
    .userInfoMainDiv ul.userInfoDetails li label{
        color: #8D8D8D;
        font-size: 15px;
        font-weight: 400;
        line-height: 20.48px;
        text-align: left;
        margin-bottom: 0;
    }
    .userInfoMainDiv ul.userInfoDetails li img{
        margin-right: 8px;
    }
    .userInfoMainDiv .listOptDiv{
        display: flex;
        justify-content: space-between;
        align-items: center;
        }
    .userInfoMainDiv ul.userMedicalInfo li{
        margin-right: 20px; 
        align-items: center;
        border: 1px dotted #C6C6C6; 
        padding: 5px 10px;
        max-width: 160px;
        border-radius: 6px;
        min-width: 100px;
        display: grid;
    }
    .userInfoMainDiv ul.userMedicalInfo li b{
        display: inline-block;
        width: 100%;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 22.34px;
        text-align: left;
        
    }
    .userInfoMainDiv ul.userMedicalInfo.CheckExpiryList li:not(:first-child),
    .userInfoMainDiv ul.userMedicalInfo.CheckExpiryList li:not(:first-child) label {
        color: #ca3030 !important;
    }
    .userInfoMainDiv ul.userMedicalInfo li label{
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.38px;
        text-align: left;
        color: #8D8D8D;

    }
    .userInfoMainDiv ul.userMedicalInfo li:last-child{
        margin-right: 0;
    } 
    .nodataRecords{
        border-top: 2px solid #666;
        border-radius: 0;
        margin-top: 10px;
    }
    .viewPatientMain .table-container {
        height: calc(100vh - 430px);
        width: 98%;
        overflow-y: auto;
        margin-bottom: 20px;
        border: 0px;
        padding: 0px;
    }
    .patientSummaryMain .tab-content {
        /* height: calc(100vh - 335px); */
        width: 100%;
        height: 100%;
        /* overflow-y: auto; */
        margin-bottom: 0px;
        border: 0px;
        padding: 0px;
        border: 1px solid #E6E6E6;
        border-radius: 0 0 14px 14px;
    }
    .patientSummaryMain .nav-tabs .nav-link{
        border-radius: 10px 10px 0px 0;
        background-color: #fff;
        color: #999;
        margin-right: 4px;
        border-bottom: 1px solid #E6E6E6; 
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        padding: 12px 40px;    
    }
    .patientSummaryMain .nav-tabs .nav-item.show .nav-link, 
    .patientSummaryMain .nav-tabs .nav-link.active {
        color: #fff;
        border-radius: 10px 10px 0px 0;
        background-color: #024C9C;
        border-color: #dee2e6 #dee2e6 #fff;
    }

   .displayFlexCenter {
        display: flex;
        align-items: center;
        justify-content: center;
    }
   .addAdminBtn a {
        color: #333;
        text-decoration: none;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
    }
    .adminDashboardMain .sideBarMenu ul.sidenavList li .filterInvert{
        filter: invert(1);
    }
    .adminDashboardMain .sideBarMenu ul.sidenavList li.active .filterInvert{
        filter: invert(0);
    }
    .adminDashboardMain .sideBarMenu ul.sidenavList li.active .filterInvertBlack{
        filter: invert(1);
    }
   .addAdminBtn a img{
        width: 20px;
        margin-right: 5px;
    }
    .loginBoxDiv.addAdminUser {
        box-shadow: 0px 0px 15px 0px #00000021;
        padding: 30px;
        border-radius: 20px;
        position: relative;
        top: 20px !important;
        left: 20px;
        transform: none !important;
        width: 97% !important;
        height: 90vh;
    }
      .LoaderSpinnerDiv {
        position: absolute;
        background: rgb(69 69 69 / 33%);
        width: 100%;
        height: 100%;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .LoaderSpinnerDiv .loader {
        box-sizing: border-box;
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 25%;
        max-width: 25%;
        height: 200px;
        align-items: center;
        justify-content: center;
    }
   

    @-webkit-keyframes ball-spin-fade-loader {
        50% {
          opacity: 0.3;
          -webkit-transform: scale(0.4);
          transform: scale(0.4);
        }
        100% {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
      
      @keyframes ball-spin-fade-loader {
        50% {
          opacity: 0.3;
          -webkit-transform: scale(0.4);
          transform: scale(0.4);
        }
        100% {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
      
      .ball-spin-fade-loader {
        position: relative;
        top: -10px;
        left: -10px;
      }
      .ball-spin-fade-loader > div:nth-child(1) {
        top: 25px;
        left: 0;
        -webkit-animation: ball-spin-fade-loader 1s -0.96s infinite linear;
        animation: ball-spin-fade-loader 1s -0.96s infinite linear;
      }
      .ball-spin-fade-loader > div:nth-child(2) {
        top: 17.04545px;
        left: 17.04545px;
        -webkit-animation: ball-spin-fade-loader 1s -0.84s infinite linear;
        animation: ball-spin-fade-loader 1s -0.84s infinite linear;
      }
      .ball-spin-fade-loader > div:nth-child(3) {
        top: 0;
        left: 25px;
        -webkit-animation: ball-spin-fade-loader 1s -0.72s infinite linear;
        animation: ball-spin-fade-loader 1s -0.72s infinite linear;
      }
      .ball-spin-fade-loader > div:nth-child(4) {
        top: -17.04545px;
        left: 17.04545px;
        -webkit-animation: ball-spin-fade-loader 1s -0.6s infinite linear;
        animation: ball-spin-fade-loader 1s -0.6s infinite linear;
      }
      .ball-spin-fade-loader > div:nth-child(5) {
        top: -25px;
        left: 0;
        -webkit-animation: ball-spin-fade-loader 1s -0.48s infinite linear;
        animation: ball-spin-fade-loader 1s -0.48s infinite linear;
      }
      .ball-spin-fade-loader > div:nth-child(6) {
        top: -17.04545px;
        left: -17.04545px;
        -webkit-animation: ball-spin-fade-loader 1s -0.36s infinite linear;
        animation: ball-spin-fade-loader 1s -0.36s infinite linear;
      }
      .ball-spin-fade-loader > div:nth-child(7) {
        top: 0;
        left: -25px;
        -webkit-animation: ball-spin-fade-loader 1s -0.24s infinite linear;
        animation: ball-spin-fade-loader 1s -0.24s infinite linear;
      }
      .ball-spin-fade-loader > div:nth-child(8) {
        top: 17.04545px;
        left: -17.04545px;
        -webkit-animation: ball-spin-fade-loader 1s -0.12s infinite linear;
        animation: ball-spin-fade-loader 1s -0.12s infinite linear;
      }
      .ball-spin-fade-loader > div {
        background-color: #ffffff;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        margin: 2px;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        position: absolute;
      }
      
      .tab-content>.active .chatbotConversation {
        background: #fff;
        border-radius: 15px;
        box-shadow: 0 0 0;
        padding: 10px;
        margin: 0px auto;
        width: 100%;
        position: relative;
    }
    .viewBtnClass {
        background-color: #024C9C;
        color: white;
        border-radius: 8px;
        border: 0;
        font-size: 14px;
        padding:  5px;
        width: 70px; 
    }
    .tab-content .despInfoDiv{
        width: 100%;
        border-bottom: 1px solid #666;
        display: inline-block;
        justify-content: space-between;
        margin-bottom: 20px;
    align-items: center;
    }
    .tab-content .despInfoDiv ul {
        display: flex;
        /* width: 320px; */
        width: auto;
        justify-content: space-between;
        list-style: none;
        padding: 0 10px 0 0px;
        margin: 0 0 5px 0;
        position: relative;
    }
    .tab-content .despInfoDiv ul li label{
        color: #515151;
        font-size: 15px;
        font-weight: 800;
        line-height: 22px;
        text-align: left;
 
    }
    .tab-content .innerDivBox{
        height: calc(100vh - 450px);
        overflow: auto;
    }
    .tab-content .innerDivBoxPC{
        height: calc(100vh - 390px);
        overflow: auto;
    }
    .tab-content .despInfoDiv ul li b{ 
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: left; 
        display: block;
        /* width: 150px; */
    }
    pre{
        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap !important;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        background: #fff;
        width: 100%;
        padding: 0;
        margin: 0;
        overflow: auto;
        overflow-y: hidden; 
        font-size: 16px;
        font-weight: 400;
        line-height: 21px; 
        font-family: 'Inter', sans-serif; 
    }

   .searchAdminUser .inputDivBox {
        border: 1px solid #F2F2F2;
        background: #fff;
        border-radius: 20px;
        width: 320px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 6px 15px;
        margin-right:8px;
        position: relative;
        height: 39px;
    }
    .searchAdminUser .inputDivBox .reset-button{
        position: absolute;
        right: 12px;
        top: 9px;
        width: 20px;
    }
    .searchAdminUser .inputDivBox input{
        width: calc(100% - 25px);
        border: 0; 
        font-size: 14px;
        font-weight: 400;
        line-height: 18.84px;
        text-align: left;
        color: #333;
        padding-left: 10px;
    }
    .searchAdminUser .inputDivBox input::placeholder{
        color: #98A7B5;
    }
    .indexGastroTxt{
        font-size: 24px;
        margin-bottom: 10px;
        display: inline-block;
        text-transform: capitalize;
    }
    .editSaveOpt button {
        background-color: #024C9C;
        color: white;
        border-radius: 8px;
        border: 0;
        font-size: 15px;
        padding: 10px 15px;
        display: inline-block;
        justify-content: space-evenly;
        align-items: center;
        width: auto; 
        margin-left: 10px;
    }
    .QueryInputDivBox .inputField a.deactiveBtnClass,
    .newFillDetailForm .deactiveBtnClass{
        opacity:1; 
        background: #a1dee2;
    }
    .QueryInputDivBox .inputField a.deactiveBtnClass img,
    .newFillDetailForm .deactiveBtnClass img{
        opacity:1;  
    }
    .reportListOptLink{
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
    }
    .reportListOptLink li  {        
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e2e2e2 ;
    }
    .reportListOptLink li:last-child  {     
        border-bottom: 0px solid #e2e2e2 ;
    }
    .reportListOptLink li a{
        color: #000000;
        text-transform: capitalize; 
        text-decoration: none;
    }
    .innerDivRecords a.editBtnInner,
    .innerDivRecords h2 a.editBtnNew {
        text-transform: capitalize;
        text-decoration: none;
        background: #013c7d;
        padding: 0;
        border-radius: 5px !important;
        line-height: 100%;
        height: 30px;
        font-size: 14px;
        color: #fff;
        margin-right: 10px;
        display: flex;
        width: 80px;
        float: right;
        justify-content:space-evenly;
        align-items: center;
        margin-bottom: 6px;
    }
     a.editBtnNewSummary {
        text-transform: capitalize;
        text-decoration: none;
        background: #013c7d;
        padding: 0;
        border-radius: 5px !important;
        line-height: 100%;
        height: 30px;
        font-size: 14px;
        color: #fff;
        margin-right: 10px;
        display: flex;
        width: 80px;
        float: right;
        justify-content:space-evenly;
        align-items: center;
        margin-bottom: 6px;
    }
    .reportListOptLink li a.downloadLink{
        text-transform: capitalize;
        text-decoration: none;
        background: #013c7d;
        padding: 0;
        border-radius: 5px !important;
        line-height: 100%;
        height: 30px;
        font-size: 14px;
        color: #fff;
        margin-right: 10px;
        display: flex;
        width: 120px;
        float: right;
        justify-content: center;
        align-items: center;
    }
    .innerDivRecords h2 a.editBtnNew:hover,
    .reportListOptLink li a.downloadLink:hover{
        background: #c2d8f0;
        color: #013c7d;
    }
    .innerDivRecords h2 a.editBtnNew img,
    .reportListOptLink li a.downloadLink img{
        filter: invert(1) brightness(10) grayscale(1);
    }
    .reportListOptLink li a.downloadLink:hover img{
        filter: invert(1) brightness(0);
    }
    .innerDivRecords h2 a.editBtnNew:hover img{
        filter: none;
    }
    .singleLineEllipsis{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width:95%;
    }
    .InsuranceIDEllipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        display: inline-block;
    }
    .patientFullnameTxt .fullnameTxt{
        width: 150px;
    }
    .ForgotPassswordMain .userforgotpwddiv.measure.w-100{
        width: 100% !important;
    }
    .ForgotPassswordMain .measure.w-100{
        width: 800px !important;
    }
    
    .ForgotPassswordMain .measure.w-100 h2{
        padding-left: 0;
    }

  .suggestionMedicineList  {
        max-height: 200px;
        overflow-y: auto;
        width: calc(100% - 100px);
        box-shadow: 0 0 2px #000;
        border-radius: 6px;
        padding: 0;
        list-style: none;
        margin-top: 4px;
    }
  .suggestionMedicineList  li{
    padding: 10px 15px;
    border-bottom:1px solid #999 ;
  }
  .suggestionMedicineList  li:hover{
    background: #cfe4f3;    
    cursor: pointer;
  }
 
  .switch-container {
    position: relative;
  }
  
  .switch {
    display: none;
  }
  
  .switch-slider {
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .switch-knob {
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.2s;
  }
  
  input:checked + .switch-slider {
    background-color: #bada55;
  }
  
  input:checked + .switch-slider .switch-knob {
    left: 21px;
  }
.radioConvertDiv{
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 120px;
}
 .radioConvertDiv label{
    display: flex !important;  
    align-items: center;
 } 
 .radioConvertDiv label input{
    margin-right: 5px;
    height: 16px; 
    border: 0;
    width: 16px !important;
    position: relative; 
    margin-right: 4px;
 }
.stepFirstForm .inputDiv label.labelTxt{
    width:55px;
    text-align: right;
    padding-right: 10px;
    margin-bottom: 0;
 }

 .timepicker {
    border: 1px solid rgb(163, 175, 251);
    text-align: left;
    display: inline;
    border-radius: 4px;
          padding:2px;
          background: #fff;
}
.loginBoxDiv .inputTxtNewBox .timepicker {
    border: 0px solid rgb(163, 175, 251);
    padding: 4px 10px;
}
.loginBoxDiv .inputTxtNewBox .timepicker .hh {
    width: 50px;
    margin-right: 2px;
}
.loginBoxDiv .inputTxtNewBox .timepicker .mm {
    width: 34px;
    margin: 0 2px;    
    text-align: center;
}
    .timepicker .hh{
        width: 60px;
        outline: none;
        border: none;
        text-align: left;
    }
     .timepicker .mm {
        width: 40px;
        outline: none;
        border: none;
        text-align: left;
    }

  .stepFirstForm .timepicker{
    border: 0px solid rgb(163, 175, 251);
    width: 60%;
  }
  .stepFirstForm .timepicker .hh,
  .stepFirstForm .timepicker .mm{
    width: 40px !important;
    text-align: center;
  }

  .uploadBtn{
    float: right;
    background: #024C9C;
    border: 1px solid;
    border-radius: 50px;
    color: #fff;
    width: 110px;
    height: 32px;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 8px;
  }
  .loginBoxDiv .inputTxtBox.uploadImgDiv{
    padding: 8px 10px 18px;
  }
  .previewImg{
    float: right;
    margin-right: 12px; 
    position: absolute;
    right: 5px;
    top: 8px;
} 
  .loginBoxDiv .inputTxtBox.uploadImgDiv label {
    padding-left: 16px;
    height: 16px;
  }
  .removedBtn{
    border-radius: 50px;
    border: 0;
    background: #000000;
    color: #fff;
    width: 20px;
    height: 20px;
    font-size: 10px;
    position: absolute;
    top: 0px;
    right: -5px;
}
 .previewImg b{
    width: 62px;
    height: 34px;
    border-radius: 5px;
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .previewImg b img{
    width: 100% !important;
    height:  100% !important;
    object-fit: contain;
}

.loginBoxDiv .singUpThreeStep  .inputTxtBox{
    height: 48px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 10px 0 0;
}

.loginBoxDiv .inputTxtBox img.cardIcon {
    padding-left: 5px;
    width: 34px;
    margin-top: 8px;
}
.myinsuranceinfoMain.loginBoxDiv{
    width: 1200px;
}
.MyInsuranceInnerDiv{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 30px;
}
.MyInsuranceInnerDiv .inputDivBox{
    width: 94%;
}

.loginBoxDiv .inputTxtBox.cardImageDiv{
    height: 212px !important;
    border-radius: 20px;
}
.loginBoxDiv .inputTxtBox.cardImageDiv  .previewImg b img,
.loginBoxDiv .inputTxtBox.cardImageDiv img{
    width: 100% !important;
    object-fit: contain;
    height: 100% !important;
}
.inputTxtNewBox.registerInputDiv.disabled .timepicker{
    background: #E9E9E9;
}
.inputTxtNewBox.registerInputDiv.disabled,
.loginBoxDiv .inputTxtBox.disabled, 
.registerInputDiv.disabled .react-tel-input input {
    background: #E9E9E9;
    height: 48px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 10px 0 0;
}
.myinsurancecardImg  .previewImg b,
.myinsurancecardImg{
    width: 100%;
    display: inline-block;
    height: 100%;
    background: transparent;
}
.myinsurancecardImg .previewImg {
    float: none;
    margin-right: 12px;
    position: relative;
    right: unset;
    top: unset;
    width: 100%;
    height: 100%;
    display: inline-block;
}
.myinsurancecardImg  .removedBtn {
    border-radius: 50px;
    border: 0;
    background: #000000;
    color: #fff;
    width: 24px;
    height: 24px;
    font-size: 12px;
    position: absolute;
    top: 12px;
    right: 12px;
}

.myinsuranceinfoMain  .saveOptDivBox {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 60px;
}
.myinsuranceinfoMain  .saveOptDivBox button{
    margin-right: 0px !important;
}
.uploadInnerDiv{
    display: grid;
    width: 100%;
    height: auto;
    text-align: center;
    padding: 40px 0;
}
.loginBoxDiv .inputTxtBox.cardImageDiv .uploadInnerDiv img.uploadIcon{
    width: 54px !important;
    margin: auto;
}
.cardUploadTxt{
    height: 24px;
    margin-top: 12px;
    color: #7F7F7F;
    font-weight: 500;
}

.maskLabelTxt{
    position: absolute;
    top: 11px;
    left: 45px;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    background: #e9e9e9;
    padding: 3px 8px;
}
.PatientDashboardMain .chatbotConversation .messageListBox {
    height: calc(100vh - 282px); 
    margin-bottom: 0;
}
.PatientDashboardMain .tab-content .innerDivBoxPC {
    height: calc(100vh - 320px);  
    margin-bottom: 50px;
}
.PatientDashboardMain .tab-content .innerDivBox {
    height: calc(100vh - 322px); 
    margin-bottom: 0;
}
.PatientDashboardMain .sideBarInfoDiv{
    float: left;
    display: inline-block;
    width: 275px;
    height: auto;
    background: #F3F6F9;
    box-shadow: 0px 2px 2px 0px #00000040;
    border-radius: 8px;
    margin: 20px;
    padding: 30px 10px 10px;
}
.PatientDashboardMain .sideBarInfoDiv .singleLineEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
}
.PatientDashboardMain{
    display: inline-block;
    width: calc(100% - 300px);
}
.PatientDashboardMain .sideBarInfoDiv {
    background: #ECEEF0;
}
.PatientDashboardMain .sideBarInfoDiv .userInfoDetails{
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
    gap: 10px;
    text-align: center;
    color:  #212121; 
    font-size: 15px;
    font-weight: 400;
    line-height: 20.48px; 
    
}
.PatientDashboardMain .sideBarInfoDiv .userInfoDetails li{
    display: flex;
    justify-content: center ;
    align-items: center;
}
.PatientDashboardMain .sideBarInfoDiv .userInfoDetails li img{
    margin-right: 5px;
    width: 18px;
    height: auto;
}
.PatientDashboardMain .sideBarInfoDiv .userInfoDetails li:nth-child(3) img{
    width: auto;
    height: 16px;
}
.PatientDashboardMain .sideBarInfoDiv .userInfoDetails .imgBox {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin: auto;
}
.PatientDashboardMain .sideBarInfoDiv .userInfoDetails .imgBox b{ 
    font-size: 36px;
    font-weight: 400;
    line-height: 50.26px; 
    color: #000000; 
}
.PatientDashboardMain .sideBarInfoDiv .userInfoDetails li h2{
    font-size: 22px;
    font-weight: 400;
    line-height: 30.71px;
    text-align: center;
}
.PatientDashboardMain .patientSummaryMain .innerDivRecords{
    position: relative;
    margin: 6px 0px 0;
}
.PatientDashboardMain .contentInnerBody .viewPatientMain .titleDiv {
    padding: 20px 30px 10px;
}
.PatientDashboardMain .patientSummaryMain .backBtn{
    position: absolute;
    right: 0;
    top: 0;
    background: #024C9C;
    width: 90px;
    height: 40px;  
    border-radius: 6px; 
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    border: 0;
}
.PatientDashboardMain .patientSummaryMain .backBtn img{
    filter: brightness(0) invert(1);
}
.PatientDashboardMain .appointmenttable {
    width: calc(100% - 320px);
    display: inline-block;
    margin-top: 16px;
}
.PatientDashboardMain .viewPatientMain .table-container {
    height: calc(100vh - 255px);
    width: 98%;
    overflow-y: auto;
    margin-bottom: 20px;
    border: 0px;
    padding: 0px;
}
.PatientDashboardMain .viewPatientMain .table-container thead {
    background-color: #F3F6F9;
}
.PatientDashboardMain .viewPatientMain .table-container thead th {
    padding: 20px 30px 16px; 
    border-bottom: 0px solid #E3E3E3;
}
.cardImgBtn{
    background: transparent;
    border: 0;
    height: auto;
    padding: 0;
    text-decoration: underline;
    color: #024C9C; 
    font-size: 17px;
    font-weight: 400;
    line-height: 22.88px; 
    
}
.sideBarInfoDiv .userMedicalInfo{
    padding: 0;
    list-style: none;  
    width: 100%;
    margin: auto;
}
.sideBarInfoDiv .userMedicalInfo li{
    width: 48%;
    display: grid;
    grid-template-columns: 100%;
    float: left;
    text-align: center; 
    border-radius: 6px;
    border: 1px dashed #000;
}

.sideBarInfoDiv .userMedicalInfo h3{ 
    display: none;
}
.dateExpiryBtn{
    background: #ca3030 !important;
}
.errorCardExpiry{
    color: #bf1d1d;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d58b8b;
    width: 100%;
    margin: 10px 0;
    padding: 5px;
}
.inputTxtNewBox.dateExpiryTxt{
    border: 1px solid #ca3030;
    box-shadow: 0px 1px 4px 0px #ca3030;
    color: #ca3030;
}
.loginBoxDiv .inputTxtNewBox.dateExpiryTxt .timepicker .hh,
.loginBoxDiv .inputTxtNewBox.dateExpiryTxt .timepicker .mm { 
    color: #ca3030;
}
.sideBarInfoDiv .userMedicalInfo.dateExpiryTxt h3{ 
    color: #ca3030;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.88px; 
    display: flex;
        align-items: center;
        justify-content: center;
}
.sideBarInfoDiv .userMedicalInfo.dateExpiryTxt li{ 
    border: 1px dashed #ca3030;
    color: #ca3030;
}
.sideBarInfoDiv .userMedicalInfo li:first-child{
    margin-right: 10px;
}
.sideBarInfoDiv .userMedicalInfo li:last-child{
    width: 100%;
    margin-top: 10px;
}

.sideBarInfoDiv .OptionDivInfo{
    padding: 0;
    list-style: none;  
    width: 100%; 
    display: inline-block;  
}
.sideBarInfoDiv .OptionDivInfo li {
    margin-top: 20px;
    float: left;
    display: inline-block;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    height: 38px; 
}
.sideBarInfoDiv .OptionDivInfo li:first-child {
    width: 56%;
    height: 38px;  
    float: left;
    display: inline-block;
    margin-right: 10px;
}
.createNewAppointmentBtn{
    width: auto;
    height: 38px; 
    border-radius: 6px;
    padding:5px 14px;
    background: #d15619;  
    letter-spacing: 0.02em; 
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sideBarInfoDiv .OptionDivInfo li:first-child button{
    width: 151px;
    height: 38px; 
    font-size: 16px;
    border-radius: 6px;
    background: #d15619;  
    letter-spacing: 0.02em; 
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sideBarInfoDiv .OptionDivInfo li:first-child button img{
    margin-right: 4px;
}
.sideBarInfoDiv .OptionDivInfo li:last-child {
    display: grid;
    grid-template-columns: 100%;
    /* float: right; */
    text-align: center; 
    border-radius: 6px;
    border: 1px dashed #000;
    padding: 0;
    width: 100px; 
    gap: 0px;  
}
.sideBarInfoDiv .OptionDivInfo li:last-child b{
    font-size: 16px;
    margin-top: 2px;
}
.sideBarInfoDiv .userMedicalInfo li{
    height: 45px; 
    gap: 0;
    align-items: center;
    display: inline-block;
    padding: 5px 0;
}
 .float-right{
    float: right !important;
 }
.sideBarInfoDiv .userMedicalInfo li b {
    font-size: 13px;
    line-height: 14px;
    display: block;
}
.sideBarInfoDiv .userMedicalInfo li label {
    font-size: 12px;
    line-height: 12px;
}
.dateNgenderTxt b{
    font-weight: 400;
    font-size: 13px;
}
.optionSignin{
    border-bottom: 1px solid #5a5a5a;
    color: #5a5a5a !important;
    font-size: 14px !important;
    line-height: .1em;
    margin: 0px 0 30px;
    text-align: center; 
}
.optionSignin span {
    background: #e5edf9;
    color: #5a5a5a;
    font-weight: 400;
    line-height: 20px;
    padding: 0 10px;
    position: relative;
    top: 10px;
}
.VerificationOTPMain{
    text-align: center;
    margin-top: 50px;
}
.VerificationOTPMain .inputOTP{
    width: 40px;
    height: 40px;
    margin: 5px;
    text-align: center;
    font-size: 20px;
    border: 0px; 
    background: transparent;
}
.VerificationOTPMain .ResendOTPBtn{ 
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #B9B9B9; 
}
.VerificationOTPMain .ResendOTPBtn button{
    border: 0;
    background: transparent;
    color: #024C9C;
    font-weight: 600;
}
.VerificationOTPMain .ResendOTPBtn button:disabled{
    color: #B9B9B9; 
}
.VerificationOTPMain  .ResendOTPBtn .countdownTxt{
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    color: #3f3e3e;
    margin-top: 10px;
    margin-bottom: 0;
    display: block;
    height: 40px;
}
.VerificationOTPMain .titleDespTxt{
    margin-top: 20px;
    margin-bottom: 20px; 
    font-size: 24px;
    font-weight: 700;
    line-height: 40.1px; 
    color: #000000;

}
.summaryReportBtn{
    display: flex;
        gap: 20px;
        align-items: center;
}
.scrollHeightDiv{
    height: calc(100vh - 250px);
    overflow: auto;
}
.VerificationOTPMain p{ 
    font-size: 17px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    color: #515151;

}
.VerificationOTPMain .signInBtn{
    margin-top: 10px;
    width: 200px;
}
.pdfButton{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #c3c3c3;
    /* border: 0px solid #c3c3c3; */
    border-radius: 6px;
    padding: 2px 10px;
    width: 120px; 
    height: 32px;
    cursor: pointer;
}
.pdfButton label{
cursor: pointer;
}
.pdfButtonModal{
    width: 200px;
    display: flex;
    justify-content: right;
    align-items: center;
    float: right;
    gap: 10px;
}
.ModalPhysicalExam {
    width: 600px;
    height: 350px;
    margin: auto;
    border-radius: 10px;
    padding: 20px;
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204); 
    overflow: auto;
    outline: none;
    background: rgb(255 255 255 / 100%);
    box-shadow: 0px 3px 21px 1px #0000001A;
}
.ModalPhysicalExam .stepFirstForm {
    background: rgb(255 255 255 / 60%);
    box-shadow: 0px 0 0;
    border-radius: 14px;
    padding: 30px 25px;
    display: flex;
    margin-top: 0px !important;
    width: 100%;
}
.tab-content .despInfoDiv ul li{
    width: 150px;
}
.headDespInfoDiv{
    display: flex;
    justify-content: space-between;
    position: relative;
}
.optionUpdateList{
    float: right;
}
.patientStatusBox{
    position: absolute;
    left: 0;
    background: #fff; 
    box-shadow: 0px 3px 21px 1px #0000001A;
    border-radius: 14px;
    border: 0 !important;
}
.patientStatusBox.EditDurationBox{
    position: absolute;
    right: 0;
    left: unset;
    background: #fff; 
    box-shadow: 0px 3px 21px 1px #0000001A;
    border-radius: 14px;
    border: 0 !important;
}
.selectedItemBtn{
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    color: #000;
    font-weight: 700;
    border-radius: 8px; 
    font-size: 13px;
    padding: 5px 10px ;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px; 
    height: 32px;  
    text-transform: capitalize;
}

.selectedItemBtn.cancelBtn{
    background: #fff; 
    border: 1.5px solid #c3c3c3;
    border-radius: 6px;
    color: #515151;
    font-weight: 400;
} 
.selectedItemBtn.newClickBtn,
.selectedItemBtn.colornew,
.selectedItemBtn.colorNew{
    background-color: transparent;
    border: 1px solid #024C9C !important;
    color: #024C9C !important;
} 
.selectedItemBtn.colorprogress,
.selectedItemBtn.colorProgress{
    background-color: transparent;
    border: 1px solid #029149;
    color: #029149;
} 
.patientStatusBox .newBtn {
    background-color: #024C9C;
    color: white;
    border-radius: 8px;
    border: 0;
    font-size: 13px;
    padding: 5px 10px ;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px; 
    height: 32px;
}
.patientStatusBox .newBtn.cancelBtn{
    background-color: transparent;
    border: 1px solid #024C9C;
    color: #024C9C;
}
.patientStatusBox ul li.active{
    color: #fff;
    border-bottom: 2px solid #024C9C; 
    background: #024C9C;
    font-weight: 700;
}
.patientStatusBox ul li{
    color: #999;
    background: #dadada;
    border-bottom: 0px solid #024C9C;
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 14px;
    text-align: center;
}
.PhysicalExamDivBox{
    width: 350px; 
    margin-top: 10px;
    background: #fff !important;
}
.PhysicalExamDivBox .stepFirstForm{
margin-top: 0 !important;
width: 100% !important;
background: #fff !important;
box-shadow: 0 0 0;
padding: 10px 20px  !important;
}
.PhysicalExamDivBox .stepFirstForm .inputDiv{
    margin-top: 0;
}
.viewDocModal{
    position: absolute;
    inset: 40px;
    border: 0px solid rgb(204, 204, 204);
    border-bottom: 1.5px solid rgb(204, 204, 204);
    background: transparent;
    overflow: hidden;
    border-radius: 0;
    outline: none;
    padding: 0;
    width: 80%;
    height: 80%;
    margin: auto;
}
.viewDocModal .innerContainer{
    border: 1.5px solid rgb(204, 204, 204);
}
.viewDocumentList{
    height: auto;
    width: 96%;
    overflow-y: auto;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 0px;
    border: 2px solid #E6E6E6;
    background: #fff;
    margin: auto;
}
.viewDocumentList a{
    color: #fff !important;
}

.zoomBtn{
    width: 100%;
    height: 32px; 
    border-radius: 10px;
    margin-left: 0px;
    display: inline-block;
    padding: 0;
    overflow: hidden;
    border: 0;
    background-color: transparent;
    border: 1px solid #024C9C;
    color: #024C9C;
    padding: 2px 5px;
} 
.zoomBtn:disabled{
    border: 1px solid #e2e2e2;
    color: #e2e2e2;
    background-color: #e2e2e2;
}
.zoomBtn:disabled img{
    filter: grayscale(8) opacity(0.5);
}
.zoomBtn img{
    width: 100%;
    height: 80%;
    object-fit: contain;
    object-position: center;
}
.tab-content .despInfoDiv ul.optionUpdateList{
    width: 420px;
    justify-content: right;
}
.tab-content .despInfoDiv ul.optionUpdateList li b {
    font-size: 14px; 
    line-height: 32px; 
}
.option-item {
    cursor: pointer;
    padding: 10px;
  }
  
  .option-item.active {
    font-weight: bold;
    color: white;
    background-color: blue; /* Highlight the active option */
  }
.optionUpdateList .editIcon{
    border: 0;
    background: transparent;
    padding: 0;
    width: 20px;
    margin-left: 2px;
    height: 14px;
}
.tab-content .despInfoDiv ul.infoDivPatientList li:nth-child(2){
 width: 200px;
}
.tab-content .despInfoDiv.PlanCareTab {
    border-radius: 14px;
    border: 1px solid #666;
    padding: 14px;
}
.openModalViewAllBtn{
    position: absolute;
    right:0;
    background-color: #024C9C;
    color: white;
    border-radius: 8px;
    border: 0;
    font-size: 15px;
    padding: 7px 10px 7px 7px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 110px;
    
}
.modalViewAllMain{
    width: 80%;
    height: 80%;
    margin: auto;
    border-radius: 0px;
    /* padding: 10px; */
    position: absolute;
    inset: 40px;
    /* border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto; */
    outline: none;
}
.modalViewAllMain .closeBtn{
    /* float: right;
    cursor: pointer;
    background: red;
    color: white;
    border: none;
    padding: 0;
    border-radius: 50px;
    width: 40px;
    height: 40px; */
    float: right;
    cursor: pointer;
    background: red;
    color: white;
    border: none;
    padding: 4px 10px;
    border-radius: 0px;
}
 .modalViewAllReportMain{
    margin: 0px 0 0;
    display: inline-block;
    width: 100%;
 }
.modalViewAllReportMain .downloadBtn,
    .modalViewAllMain .downloadBtn{
    padding: 0px 5px 2px;
    border: 0;
    height: 28px;
    display: inline-block;
    background: #024C9C;
    border-radius: 8px; 
    text-align: center;
    margin: 0px auto;
    margin-bottom: 20px;
    display: flex
;
}
.modalViewAllReportMain .downloadBtn img,
.modalViewAllMain .downloadBtn img{
    width: 100%;
    height: 100%;
}
.modalViewAllReportMain ul.userInfoDetails ,
.modalViewAllMain ul.userInfoDetails {
    list-style: none;
    padding: 0;
    display: flex;
    margin-bottom: 8px;
    gap: 20px;
}
.modalViewAllReportMain ul.userInfoDetails li,
.modalViewAllMain  ul.userInfoDetails li {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalViewAllReportMain ul.userInfoDetails li img,
.modalViewAllMain  ul.userInfoDetails li img {
    margin-right: 8px;
}
.modalViewAllReportMain .infoDivPatientList,
.modalViewAllMain .infoDivPatientList{
    list-style: none;
    padding: 10px 0;
    display: flex;
    margin-bottom: 8px;
    gap: 20px;
}
.modalViewAllReportMain .infoDivPatientList,
.modalViewAllMain .infoDivPatientList{
} 

.tab-content .despInfoDiv ul.infoDivPatientList li{
    width: auto !important;
    margin-right: 26px;
}
.modalViewAllReportMain .infoDivPatientList li,
.modalViewAllMain .infoDivPatientList li{    
    display: grid;  
    grid-template-columns: 100;
}
.modalViewAllReportMain h2,
.modalViewAllMain h2{
    font-size: 24px;
}
.modalViewAllReportMain .innerModalDiv{
    padding: 0px;
    border: 0px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    outline: none;
    height: calc(100vh - 105px);
    clear: both;
    border-radius: 14px;
    padding: 0 0 30px;
}
.modalViewAllMain .innerModalDiv{
    padding: 0px;
    border: 1.5px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    outline: none;
    height: 100%;
    clear: both;
}
.ConsentNoticeMain.bgInnerDiv {
    width: 100%;
    height: calc(100vh - 200px);
}
.patientStatusBox .inputFollowText{
    width: 175px;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #aeaeae;
    padding: 2px 5px;
    border-radius: 6px;
    font-size: 14px;
}
.futureDateTxt{
    font-size: 14px;
}
.reportListOptLink li .pdfButton.pdfViewBtn {
    text-transform: capitalize;
    text-decoration: none;
    color: #013c7d;
    padding: 2px 5px;
    border-radius: 5px !important;
    border: 1px solid #aeaeae;
    line-height: 100%;
    height: 30px;
    font-size: 14px;
    /* color: #fff; */
    margin-right: 10px;
    display: flex;
    width: 90px;
    float: right;
    justify-content: center;
    align-items: center;
}
.ZoomMeetMain{
    border: 1px solid rgb(204, 204, 204);
    margin-top: 10px;
    padding: 10px;
    width: 450px;
    right: 0;
    left: unset;
}
.ZoomMeetMain label.labelTxt{
    display: block;
    font-weight: 600 !important;
    font-size: 14px !important;
}
.ZoomMeetMain input.inputStyle , 
.ZoomMeetMain .inputDiv {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    margin: 0px;
}
.ZoomMeetMain .inputDiv input {
    width: 86%;
    border: 0;
}
.ZoomMeetMain .datePickerDiv .inputTimeStyle{
    width: 120px;
}
.ZoomMeetMain  ul.react-datepicker__time-list{
    display: inline-block;
}
.ZoomMeetMain  ul.react-datepicker__time-list li.react-datepicker__time-list-item{
    width: 100%;
}
.ZoomMeetMain  ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: #fff;
    font-weight: 700;
}
.ZoomMeetMain ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
    background: transparent;
    color: #000000;
}
.patientStatusBox  h2.react-datepicker__current-month,
.ZoomMeetMain .datePickerDiv  h2.react-datepicker__current-month{
    text-align: center;
    font-size: 16px;
}
.ZoomMeetMain .meetingDetailsTxt{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 12px;
    font-size: 14px; 
}
.ZoomMeetMain .meetingDetailsTxt label{
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
}
.ZoomMeetMain .meetingDetailsTxt b{
    font-weight: 700 !important;
}
.ZoomMeetMain .meetingDetailsTxt button{ 
    background-color: #024C9C;
    color: white;
    border-radius: 8px;
    border: 0;
    font-size: 15px;
    padding: 7px 10px 7px 7px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    margin: 20px auto 0;
}
.ZoomMeetMain .datePickerDiv{
    position: relative;
}
 
.blueBtnStyle{
    background-color: #024C9C;
    color: white;
    border-radius: 8px;
    border: 0;
    font-size: 15px;
    padding: 4px 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: auto; 
}
.newBlueBtn.blueBtnStyle{
    font-size: 13px;
    width: 120px;
}
.ConsentNoticeMain .contentBody{
    height: calc(100vh - 100px);
    overflow: auto;
    margin: 20px auto;
}
.tab-content .despInfoDiv.PlanCareTab {
    height: calc(100vh - 350px);
    margin-bottom: 20px;
}


.tab-content .despInfoDiv ul.followupOptList {
    display: inline-block;
    /* display: grid;
    grid-template-columns: 33% 33%  33% ; */
    width: 100%; 
    list-style: none;
    padding: 0 10px 0 0px;
    margin: 0 0 5px 0;
    position: relative;
    gap:10px;
    align-items: center;
}
.tab-content .despInfoDiv ul.followupOptList li{
    display: inline-block;
    width: auto;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 5px;
}
.tab-content .despInfoDiv ul.followupOptList li:last-child{
    width: 170px;
}
.tab-content .despInfoDiv ul.followupOptList li.active{
    background-color: #024C9C;
    color: white;
}
.ZoomMeetMain .textareaFollowupNew{
    width: 100%;
    height: 100px;
    border: 1px solid #aeaeae;
    border-radius: 10px;
    padding: 10px;
}
.followupSetMain .textareaFollowup{
    width: 90%;
    height: 150px;
    border: 1px solid #aeaeae;
    border-radius: 10px;
    padding: 10px;
}
.followupSetMain .fillDetailsForm {
    border: 0px solid #aeaeae;
    padding: 10px 0;
    align-items: center; 
}
.followupSetMain .attachmentSpan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    right: 0px;
}
.followInviteForm.ZoomMeetMain,
.followupSetMain.ZoomMeetMain{
    /* right: unset; */
}
.ZoomMeetMain .meetingDetailsTxt button:disabled {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    color: #666666;
}

.twoLineEllipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 150% !important;
}
 .standardPerecautionList{
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 10px;
    padding: 0 20px;
 }
 .standardPerecautionList li input{
    margin-right: 8px;
 } 
 .scrollHeightOverflow{
    height: calc(100vh - 170px);
    overflow: auto;
 }
 .accordion-collapse.collapse.show .accordion-body{
    height: calc(100vh - 520px);
    overflow: auto;
    margin-top: 10px;
 }
 .tab-content .accordion-body .innerDivBox {
    height: calc(100vh - 600px);
    overflow: auto;
}
.tab-content .accordion-body .textareaSummary textarea{
    height: 75% !important;
    padding: 5px;
}
.accordion-button{
    font-weight: 500;
    font-size: 18px;   
}
.accordion-button:not(.collapsed) {
    color: #002761;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
    font-weight: 700;
    font-size: 20px;
}
.accordion-button:not(.collapsed)::after {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    transform: rotate(-180deg);
    filter: contrast(0.5);
}
.accordion-button::after {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-left: auto;
    content: "";
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-size: 20px;
    transition: transform .2s ease-in-out;
}
.copyclipBtn{
    background-color: transparent;
    border: 0;
}
.copyAllclipBtn{
    cursor: pointer;
    background: #dfdfdf;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    border-radius: 8px;
    border: 0;
    margin-left: 20px;
}
.copyAllclipBtn:hover{
    background: #122968;
    color: #fff;
}
.copyAllclipBtn:hover img{
   filter: brightness(5);
}
.copyAllclipBtn img{
    margin-right: 5px;
}
.buttonDivBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 440px;
    margin: 20px auto;
    text-align: center;
} 
.buttonDivBox button{
    background: #024C9C !important;
    color: #fff;
    height: 30px !important;
    padding: 3px 10px !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
}
.shareReportDiv{
    width:650px;
    display: block;
    text-align: center;
    margin: 50px auto;
    border: 1px solid #aaa;
    padding: 20px 50px 30px;
    background: #edf2fa;
    border-radius: 10px;
}
.shareReportDiv input{
    border: 1px solid #aeaeae;
    border-radius: 8px;
    width: 400px;
    margin-right: 10px;
    height: 34px;
    padding: 5px;
}
.shareReportDiv button{
    border-radius: 5px;
    border: 0;
    background: #024C9C;
    color: #fff;
    font-size: 14px;
    height: 34px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    margin: 10px auto;
}
.shareBtn img{ 
    margin-right: 5px; 
    filter: contrast(0) grayscale(1) brightness(1);
}
.shareBtn:hover img{ 
    margin-right: 5px; 
    filter: contrast(0) grayscale(1) brightness(10);
}
.shareReportDiv button img{
    width: 20px;
    margin-right: 5px; 
    filter: contrast(0) grayscale(1) brightness(10);
}
.shareReportInputDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexDiv{
    display: flex !important;
    align-items: center;
    justify-content: start;
}
.newLeftContentDiv{
    width: 175px;
    float: left;
}
.patientSummaryMain .tab-content.newRightContentDiv{
    width: calc(100% - 180px);
    float: right;
}
.newLeftContentDiv .nav-tabs .nav-link:hover,
.newLeftContentDiv .nav-tabs .nav-item.show .nav-link, 
.newLeftContentDiv .nav-tabs .nav-link.active {
    color: #fff;
    border-radius:  0 !important;
    background-color: #024C9C;
    border-color: #dee2e6 #dee2e6 #fff;
}
.newLeftContentDiv .nav-tabs .nav-link {
    width: 100%;
    border-radius: 0;
    background-color: #fafafa;
    color: #999;
    margin-right: 4px;
    border-bottom: 1px solid #E6E6E6;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    padding: 12px 20px;
}
.patientSummaryMain .tab-content.newRightContentDiv { 
    border: 0px solid #E6E6E6;
    border-radius: 0 ;
}
.userInfoListView{
    grid-template-columns: 50% 50%;
    gap:10px;
}
.zoomLinkShowDiv{
    height: calc(100vh - 80px);
}
.zoomCallMeeting .userInfoMainDiv{
    height: calc(100vh - 80px);
    overflow-y: auto;
}
.finalReportH2Tag{
    width: 240px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.finalReportH2Tag .openModalViewAllBtn{
    padding: 2px 7px;
}
/*----------------Media Query ---------------*/
@media screen and (max-width:1800px){  
   
    .loginBoxDiv {
        box-shadow: 0px 0px 15px 0px #00000021;
        padding:  30px;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 500px;
    }
}
@media screen and (max-width:1600px){  
    .indexGastroTxt{
        font-size: 20px;
        margin-bottom: 10px;
        display: inline-block;
    }
   
    .emailVerificationDiv img {
        margin-bottom: 30px;
        width: 60px;
    }
    .emailVerificationDiv h3 {
        font-size: 20px; 
        line-height: 20px;
    }
    .emailVerificationDiv p {
        font-size: 16px; 
        line-height: 20px;
    }
    .emailVerificationDiv .signInBtn {
        width: 140px;
        height: 40px;
    }
    .headerDivBox .sidenavList .profileImg {
        width: 40px;
        height: 40px;
        font-size: 14px;
    }
    .UserProfileMain .editUserProfile, .UserProfileMain .showUserProfile { 
        gap: 10px 30px;
        width: 99%;
        padding: 0 30px 0 20px;
    }
    .UserProfileMain .inputTxtBox.registerInputDiv, .UserProfileMain .registerInputDiv {
        margin: 5px 0 0;
    }
    .loginScreen .infoDivBox {
        width: 60%;
    }
    .UserProfileMain .saveOptDivBox {
        display: flex;
        justify-content: center;
        padding: 0 0 0 50px;
        margin-top: 30px;
    }
.myinsuranceinfoMain  .saveOptDivBox button,
    .UserProfileMain .saveOptDivBox button { 
        padding: 6px 12px;
        margin-right: 36px;
        }
    .loginBoxDiv {
        box-shadow: 0px 0px 15px 0px #00000021;
        padding:  30px;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 450px;
    }
    .inputGroupDiv {
        margin-bottom: 15px;
    }
    .errorTxt{ 
        font-size: 14px;
    }
    .loginScreen .infoDivBox h1 {
        font-size: 36px;
        font-weight: 400;
        line-height: 38px;
        text-align: left;
        color: #000;
        margin-bottom: 20px;
    }
    .UserProfileMain .editUserProfile label, 
    .UserProfileMain .showUserProfile p label,
    .optDivBox .forgotPwd a,
    .loginBoxDiv .switchToogle{
        font-size: 14px; 
        line-height: 20px;
    }
    .loginScreen .infoDivBox span p,
    .loginScreen .infoDivBox p {
        font-size: 16px; 
        line-height: 20px;
    }
    .loginScreen .infoDivBox ul {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        width: 80%;
    }
    .loginScreen .infoDivBox span h3{
        font-size: 30px; 
        line-height: 30px;
    }
    .loginScreen .infoDivBox ul li img{
        width: 64px;
    }
    .loginScreen .infoDivBox ul li {
        width: 125px;
    }
     .loginScreen .infoDivBox ul.technologyListOpt li img{
        width: 20px;
    }
    .loginScreen .infoDivBox ul.technologyListOpt li {
        width: auto;
    }
    .loginScreen .infoDivBox ul.technologyListOpt li {
        width: 215px;
    }
    .signInBtn { 
        font-size: 16px; 
        line-height: 24px; 
        height: 50px; 
        margin: 30px auto;
    }
    .otherSignInOpt ul li { 
        height: 50px;
        font-size: 16px; 
        line-height: 24px; 
        margin: 0;
        padding: 0 10px; 
    }
    .titleDespTxt {
        margin-bottom: 20px;
        font-size: 20px;
    }
    .loginBoxDiv .inputTxtBox img {
        padding-left: 5px;
        width: 26px;
    }
    .loginBoxDiv .switchToogle label { 
        text-indent: -9999px;
        width: 40px;
        height: 20px;
    }
    .loginBoxDiv .switchToogle label:after { 
        top: 1.5px;
        left: 4px;
        width: 16px;
        height: 16px;
    }
    .react-tel-input .form-control { 
        font-size: 16px; 
        margin-left: 0; 
        line-height: 25px;
        height: 40px;
        padding: 10x 50px;
        box-shadow: 0px 1px 4px 0px #00000024;
    }
    .stepFirstForm .inputDiv,
    .loginBoxDiv .inputTxtBox { 
        padding: 8px 10px;
    }
    .RSPBprogressBar{
        margin-top:34px !important;
    }
    .indexedStep {
        width: 36px;
        height: 36px;
    }
    .stepFirstForm{
        width: 800px;
        padding: 20px !important;
    }
    .stepFirstForm .inputDiv input,
    .stepFirstForm .inputDiv label {
        font-size: 14px;
        line-height: 20px;
    }
    .signupDiv { 
        font-size: 14px; 
        line-height: 20px; 
        margin-top: 0px;
    }
    .stepFirstForm h2,
    .ForgotPassswordMain h2{        
        font-size: 18px; 
        line-height: 24px; 
    }
    .ForgotPassswordMain label{
        font-size: 14px; 
        line-height: 20px;  
    }
    .UserProfileMain h2 {
        font-size: 20px;
        padding-left: 16px;
    }
    .headerDivBox .sidenavList li.listOpt .accordion-collapse.collapse.show { 
        right: 10px !important;
        top: 64px !important; 
        width: 230px; 
        padding: 10px;
    }
    .headerDivBox .sidenavList li.listOpt .accordion-collapse.collapse.show ul li {
        font-size: 14px; 
        line-height: 20px; 
        height: 40px; 
    }
    .headerDivBox .sidenavList li.listOpt .accordion-collapse.collapse.show ul li b { 
        width: 32px;
        height: 32px;
    }
    .headerDivBox .sidenavList li.listOpt .accordion-collapse.collapse.show ul li b img {
        object-fit: contain;
        width: 18px;
    }
    .stepFirstForm .inputDiv.newFillDetailForm {
        display: inline-block;
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width:1380px){ 
    .maskLabelTxt{
        position: absolute; 
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
    }
    .userInfoMainDiv ul.userInfoDetails li {
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .stepFirstForm {
        width: 1000px !important; 
        padding: 20px !important;
    }
    .otherSignInOpt ul li {
        height: 40px;
        font-size: 14px;
    }
    .loginScreen .infoDivBox h1 {
        font-size: 28px;
        line-height: 30px;
    }
    .titleDespTxt{
        font-size: 18px;
        line-height: 20px; 
    }
    .signupDiv {
        font-size: 13px;
        line-height: 20px;
    }
    .loginScreen .infoDivBox span p, .loginScreen .infoDivBox p {
        font-size: 14px;
        line-height: 20px;
    }
    .loginScreen .infoDivBox ul.technologyListOpt li {
        width: 215px;
        font-size: 14px;
    }
    .loginScreen .infoDivBox span h3 {
        font-size: 20px;
        line-height: 30px;
    }
    .loginScreen .infoDivBox ul { 
        margin: 20px 0;
        width: 450px;
    }
    .loginScreen .infoDivBox ul li img {
        width: 64px;
    }

    .loginScreen .infoDivBox ul.technologyListOpt{
        width: 100%;
    }
    .UserProfileMain h2 {
        font-size: 18px;
        padding-left: 16px;
    }
    .loginBoxDiv .inputTxtBox img {
        padding-left: 5px;
        width: 22px;
    }
    .stepFirstForm .inputDiv, .loginBoxDiv .inputTxtBox {
        padding: 6px 10px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
.myinsuranceinfoMain  .saveOptDivBox button,
    .UserProfileMain .saveOptDivBox button {
        padding: 6px 12px;
        margin-right: 36px;
        font-size: 14px;
        line-height: 16px;
        width: 120px;
    }
    .myinsuranceinfoMain  .editIcon,
    .UserProfileMain .editIcon { 
        height: 20px; 
        padding: 12px; 
        font-size: 14px;
        line-height: 20px;
    }
    .myinsuranceinfoMain  .editIcon img,
    .UserProfileMain .editIcon img{ 
        width: 16px;
    }
    .signInBtn {
        font-size: 14px;
        line-height: 20px;
        height: 40px;
    }
    .ForgotPassswordMain .signInBtn {
        margin: 10px auto;
    }
    .errorTxt {
        font-size: 13px;
    }
    .loginBoxDiv { 
        padding: 20px;
    }
    .loginBoxDiv .inputTxtBox input,
    .react-tel-input .form-control,
    .UserProfileMain .inputTxtBox input {
        font-size: 14px;
        line-height: 16px;
    }
    .loginBoxDiv .inputTxtBox input, 
    .UserProfileMain .inputTxtBox input {        
        width: calc(100% - 50px);
    }
    .toggleCalendarBtn img{
        width: 20px;
        margin-top: -4px;
    }
    .UserProfileMain .editUserProfile label, 
    .UserProfileMain .showUserProfile p label, 
    .optDivBox .forgotPwd a, 
    .loginBoxDiv .switchToogle {
        font-size: 13px;
        line-height: 18px;
    }
 }
 @media screen and (max-width:1280px){
    .singleLineEllipsis{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width:90%;
        display: inline;
    }
    .userInfoMainDiv ul.userInfoDetails {
        margin-top: 10px;
        display: inline-block;
        width: 100%;
    }
    .userInfoMainDiv ul.userInfoDetails li {
        margin-right: 10px;
        display: inline-block;
    }
    .loginBoxDiv { 
        padding: 20px; 
        width: 360px;
    }
    .titleDespTxt {
        font-size: 16px;
        line-height: 20px;
    }
    .loginBoxDiv .inputTxtBox input, 
    .react-tel-input .form-control, 
    .UserProfileMain .inputTxtBox input {
        font-size: 13px;
        line-height: 16px;
    }
    .emailVerificationDiv img {
        margin-bottom: 30px;
        width: 50px;
    }
    .emailVerificationDiv h3 {
        font-size: 20px; 
        line-height: 20px;
    }
    .emailVerificationDiv p {
        font-size: 14px; 
        line-height: 18px;
    }
    .emailVerificationDiv .signInBtn {
        width: 140px;
        height: 40px;
    }
    .indexedStep {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }
    .RSPBprogressBar {
        margin-top: 30px !important;
    }
    .stepFirstForm { 
        margin-top: 40px !important;
    }
    .stepFirstForm h2, .ForgotPassswordMain h2 {
        font-size: 16px;
        line-height: 20px;
        margin: 0;
    }
    .headerDivBox { 
        width: 100%;
        padding: 5px; 
        height: 50px;
    }
    .headerDivBox .companyLogo {
        height: 100%;
    }
 }
 @media screen and (max-width:1200px){ 
    .userInfoMainDiv ul.userMedicalInfo li {
        margin-right: 10px; 
        max-width: 140px; 
        min-width: 80px; 
    }
    .ForgotPassswordMain .measure.w-100{
        width: 600px !important;
    }
    .stepFirstForm {
        max-width: 80% !important;
        padding: 20px !important;
    }
    .UserProfileMain {
        width: 800px !important;
    }
 }
 @media screen and (max-width:1100px){
    .titleDiv h2{
        font-size: 20px;
    }
    .sideBarMenu {
        width: 200px;
    }
    .adminDashboardMain .contentBody {
        width: calc(100% - 200px);
    }
    .searchAdminUser .inputDivBox { 
        width: 300px;
    }
    .titleRightSideDiv li { 
        font-size: 12px; 
        padding: 10px 12px;
    }
    .userInfoMainDiv .imgBox {
        margin-right: 20px;
        width: 95px;
        height: 95px;
    }
    .ForgotPassswordMain .measure.w-100{
        width: 80% !important;
    }
}
 @media screen and (max-width:990px){
    .container-fluid.adminDashboardMain{
        width: 990px;
    }
    .bgImageUrl {
        width: 100%;
        height: 170vh;
    }
    .stepFirstForm {
        max-width: 80% !important;
        padding: 20px !important;
    }
    .UserProfileMain {
        width: 90% !important;
    }
    .loginScreen .infoDivBox {
        width: 100%;
        padding: 0 50px 20px;
    }
    .loginBoxDiv {
        padding: 20px;
        width: 600px;
        margin: auto;
        position: relative;
        top: 0;
        left: unset;
        transform:none;
    }
    .loginScreen {
        display: inline-block;
        justify-content: space-between;
        padding-top: 20px !important;
    }
    .contentBody{
        max-width: 100% !important;
    }
    .loginScreen .col-lg-5{
        width: 100%;
    }
 }
 @media screen and (max-width:880px){
    .stepFirstForm {
        max-width: 90% !important;
        padding: 20px !important;
    }
  
 }
 @media screen and (max-width:756px){
    .UserProfileMain {
        width: 90% !important;
    }
    .UserProfileMain .editUserProfile, .UserProfileMain .showUserProfile{
        display: inline-block;
    }
    .UserProfileMain .inputDivBox {
        width: 100%;
        margin-top: 10px;
    }
    .loginBoxDiv {
        padding: 20px;
        width: 80%;
    }
    .loginScreen .infoDivBox ul {
        margin: 20px 0;
        width: 100%;
    }
    .loginScreen .infoDivBox ul.technologyListOpt { 
        display: inline-block;
    }
 }